<template>
  <div class="Categor">
    <div class="nav" v-if="Other">
      <h2>{{ data.title }}</h2>
      <p v-for="(item, index) in data.text" :key="index">
        {{ item }}
      </p>
      <img width="100%" :src="data.imgs" alt="" />
      <h4>{{ data.mintitle }}</h4>
      <div class="text">
        <div class="textt" v-for="(item, index) in data.content" :key="index">
          <h4>{{ item.title }}</h4>
          <p>
            {{ item.text }}
          </p>
          <img
            :class="{ active: router == 'SavingsTrust' && item.key == 4 }"
            width="100%"
            :src="item.img"
            alt=""
          />
        </div>
      </div>
      <img id="img" width="100%" :src="data.img" alt="" />
      <div class="table">
        <h2>{{ data.TableTitle }}</h2>
        <div class="td">
          <div class="tds" v-if="!isshow">
            <div class="tdss" v-for="(item, index) in data.table" :key="index">
              <div class="left" v-html="item.left"></div>
              <div class="center" v-html="item.center"></div>
              <div class="right" v-html="item.right"></div>
            </div>
          </div>
          <div class="tdS" v-if="isshow">
            <div class="tdSs" v-for="(item, index) in data.table" :key="index">
              <div class="left" v-html="item.left"></div>
              <div class="center1">{{ item.center1 }}</div>
              <div class="center2">{{ item.center2 }}</div>
              <div class="right">{{ item.right }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Other" v-if="!Other">
      <div class="banner">
        <img width="100%" src="../assets/others_01.jpg" alt="" />
        <div class="title">
          <div class="h1">OTHERS OTHERS</div>
        </div>
      </div>
      <div class="nav">
        <div class="content">
          <img src="../assets/others_04.jpg" alt="" />
          <div>Chattel Trust</div>
        </div>
        <div class="content">
          <img src="../assets/others_06.jpg" alt="" />
          <div>Equity Trust</div>
        </div>
        <div class="content">
          <img src="../assets/others_08.jpg" alt="" />
          <div>Credit Trust</div>
        </div>
        <div class="content">
          <img src="../assets/others_13.jpg" alt="" />
          <div>Retirement Trust</div>
        </div>
        <div class="content">
          <img src="../assets/others_14.jpg" alt="" />
          <div>Will Trust</div>
        </div>
        <div class="content">
          <img src="../assets/others_15.jpg" alt="" />
          <div>Antique Trust</div>
        </div>
        <div class="content">
          <img src="../assets/others_20.jpg" alt="" />
          <div>Revocable trust</div>
        </div>
        <div class="content">
          <img src="../assets/others_21.jpg" alt="" />
          <div>Irrevocable Trust</div>
        </div>
        <div class="content">
          <img src="../assets/others_22.jpg" alt="" />
          <div>Artwork Trust</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      router: "",
      data: {},
      Other: true,
      isshow: false,
      SavingsTrust: {
        title: "INHERITANCE SAVINGS TRUST",
        text: [
          "Inheritance savings trust is set up when the settlor transfers one’s savings to the trust for wealth planning. The client earns the growth while enjoying all the benefits that the trust has to offer. ",
        ],
        imgs: "https://www.hongkongtrust.com.hk/vue/img/SAVINGSTRUST1.png",
        img: "https://www.hongkongtrust.com.hk/vue/img/SavingsTrust3.png",
        mintitle: "THE FUNCTIONS AND ADVANTAGES OF INHERITANCE SAVINGS",
        content: [
          {
            title: "◆ A 2-in-1 structure (Private + Inheritance Savings)",
            text: "This unique trust innovatively combines the advantages of a private trust and an inheritance-savings element.",
          },
          {
            title: "◆ Protected from creditor claims / bankruptcy seizures",
            text: "Settlors of a Hong Kong trust enjoy immediate protection from any creditor claims on their personal assets. This is because when the trust is set up, the settlor would have transferred the ownership of the selected assets to an offshore trustee.",
          },
          {
            title: "◆ Protection against Inflation",
            text: "The inflation rates for most South East Asia and North Asia countries are less than 4% (source: IndexMundi, 1 Jan 2020). Inheritance savings trust assurance that the purchasing power of the settlor’s wealth is not only maintained, but even grows over time.",
          },
          {
            title: "◆ Privacy & identity protection",
            text: "Trustees in Hong Kong are obligated by law to maintain the privacy and confidentiality of the settlor’s personal information. It requires a court order from the Hong Kong court to compel the trustee to reveal the settlor’s personal identity and information.",
            img: "https://www.hongkongtrust.com.hk/vue/img/SavingsTrust2.png",
            key: 4,
          },
          {
            title: "◆ Flexibility",
            text: "Upon maturity of the inheritance savings trust, the settlor can choose to apply the proceeds, whether in its entirety or partially, as follows: to place funds in another inheritance savings trust (different tenures possible), to purchase real estate, public-listed shares or any other capital assets or just to accumulate as income in the trust for liquidity purposes. ",
          },
          {
            title: "◆ Effective inheritance",
            text: "The trustee is a licensed corporate entity and will therefore be in existence indefinitely. The settlor can be assured that one’s wishes as contained in the Trust Deed will be carried out. The Trust Deed is a legally binding document which records and formalizes the agreement between the settlor and trustee. ",
          },
          {
            title: "◆ Free from inheritance tax",
            text: "Where assets are located in countries which impose inheritance or estate tax, the death of the owner may trigger this tax to be payable. If the assets are held by a trust instead, the legal ownership of the asset is no longer with the owner but with a corporate trustee, thereby potentially avoiding any inheritance tax.",
          },
          /* {
            title: "◆ Strict regulatory supervision",
            text: "As the Savings Trust is a kind of product of the trust services, and is managed by an asset management company based in Hong Kong via a private label fund registered in Cayman Islands (a British overseas territory), the investment is cross-regulated by the Financial Secretary of the Hong Kong SAR Government, the Hong Kong Monetary Authority, the Securities & Futures Commission of Hong Kong and the Cayman Islands Monetary Authority.",
          },
          {
            title: "◆ Effective inheritance",
            text: "The trustee is a licensed corporate entity and will therefore be in existence indefinitely. The settlor can be assured that his/her wishes as contained in the Trust Deed will be carried out. The Trust Deed is a legally binding document which records & formalizes the agreement between the settlor and trustee.",
          },
          {
            title: "◆ Free from inheritance tax",
            text: "Where assets are located in countries which impose inheritance or estate tax, the death of the owner may trigger this tax to be payable. If the assets are held by a trust instead, the legal ownership of the asset is no longer with the owner but with a corporate trustee, thereby potentially avoiding any inheritance tax.",
          },
          {
            title: "◆ Trust income is legitimate & tax-exempt",
            text: "The ASR generated by the Savings Trust is a legitimate trust income for tax purposes. As this income is generated in Hong Kong , foreign-sourced income received is tax-exempt.",
          }, */
        ],
        TableTitle:
          "Conventional Savings Accounts (without Trust) VS Inheritance Savings Trust",
        table: [
          {
            left: "Description",
            center: "Conventional Savings Accounts<br/>(without Trust)",

            right: "Inheritance Savings Trust",
          },
          {
            left: "2-in-1 structure (Private + Inheritance Savings)",
            center: "No",
            right: "Yes",
          },
          {
            left: "Protected from creditor claims<br/>/ bankruptcy seizures",
            center: "No",
            right: "Yes",
          },
          {
            left: "Protection against inflation",
            center: "No",
            right: "Yes",
          },
          {
            left: "Privacy & identity protection",
            center: "No",
            right: "Yes",
          },
          {
            left: "Flexibility",
            center: "No",
            right: "Yes",
          },
          {
            left: "Strict regulatory supervision",
            center: "No",
            right: "Yes",
          },
          {
            left: "Effective inheritance",
            center: "No",
            right: "Yes",
          },
          {
            left: "Free from inheritance tax",
            center: "No",
            right: "Yes",
          },
          /* {
            left: "Effective inheritance",
            center: "No",
            right: "Yes",
          },
          {
            left: "Free from inheritance tax",
            center: "No",
            right: "Yes",
          },
          {
            left: "Trust income is legitimate & tax-exempt",
            center: "No",
            right: "Yes",
          }, */
        ],
      },
      FamilyTrust: {
        title: "FAMILY TRUST",
        imgs: "https://www.hongkongtrust.com.hk/vue/img/FAMILYTRUST1.png",
        img: "https://www.hongkongtrust.com.hk/vue/img/FAMILYTRUST3.png",
        text: [
          "Family trust refers to the trust applied in the field of family wealth management. The trustee must manage the family wealth according to the wishes of the settlor to achieve family wealth management and inheritance purposes. The beneficiaries are mainly family members.",
        ],
        mintitle: "THE FUNCTIONS AND ADVANTAGES OF FAMILY TRUST",
        content: [
          {
            title: "◆ Timing of wealth inheritance",
            text: "When a person dies intestate (without a will), the surviving family members will need to appoint an administrator to go through the estate administration process. There may be delays as family members may be in conflict as to who should be the administrator. Even with a will, the entire probate process may take 6-24 months to complete. Assets in a trust can bypass this process and beneficiaries can receive the benefits immediately.",
          },
          {
            title: "◆ Probate process",
            text: "The estate administration process (if without a will) generally takes longer to complete than the probate process (if a will exists) because of the additional procedural requirements in the former process. Assets settled in the trust are no longer in legal possession of the deceased and are therefore not be frozen upon death.",
          },
          {
            title: "◆	Preserved from mismanagement of assets",
            text: "Whether distributions are made in accordance with intestacy laws or a will, they will be given in their entirety to the beneficiaries. Those who have little knowledge and skills in managing so much wealth, or those who have certain negative habits like gambling, are likely to end up squandering their inheritance. With a trust, the settlor can specify the manner of distribution to ensure that the beneficiary’s needs are provided for and the bulk of the wealth is preserved at the same time.",
          },
          {
            title: "◆	Confidentiality of distribution",
            text: "Whether there is a will or otherwise, all beneficiaries that are entitled to the estate will have knowledge of the amount distributed to each of them. This may cause unhappiness and even conflict among them because some of them may feel entitled to a bigger proportion compared to others, resulting in potential lawsuits among the beneficiaries. Trust beneficiaries will only have knowledge of what he/she is receiving from the trust.",
          },
          {
            title: "◆	Effectiveness of distribution",
            text: "The personal representative, i.e. the administrator (in the case of intestacy) or the executor (in the case of testacy), is an individual who may not deal fairly with the beneficiaries, as this role may be viewed as doing a favor for the beneficiaries. Trustees are obligated by law to carry out their duties in accordance with the instructions contained in the Trust Deed and/or Letter of Wishes.",
            img: "https://www.hongkongtrust.com.hk/vue/img/FAMILYTRUST2.png",
          },
          {
            title: "◆	Extent of wealth inheritance",
            text: "In cases of intestacy, the estate will be distributed to spouse, children and/or parents only. With a will, the executor will distribute all the assets to named beneficiaries in accordance with the will. For assets placed in a trust, the trustee can be instructed to distribute the assets / income over a long period of time, even spanning across generations who are yet to be born.",
          },
          {
            title: "◆	Preserved from estate duty or related tax",
            text: "One of the requirements of the estate administration or probate process is for the administrator / executor to submit the final tax returns and to pay the final tax payable. There is also a possible inheritance tax if there are assets situated in countries which impose this tax. Assets in an offshore trust are not subject to any kind of tax upon death of the settlor.",
          },
          {
            title: "◆	Dispute over wealth inheritance",
            text: "Distribution in accordance with a will can be challenged if any beneficiaries are dissatisfied with the portion they receive, or if they were excluded from the will. Distributions made from a trust are private, and individual beneficiaries will not have any knowledge of what other beneficiaries receive. As such, it makes challenging trust distributions extremely difficult.",
          },
          {
            title: "◆	Protected from creditor claim during lifetime",
            text: "Settlors of a Hong Kong trust enjoy immediate protection from any creditor claims on their personal assets. This is because when the trust is set up, the settlor would have transferred the ownership of the selected assets to the trustee.",
          },
        ],
        TableTitle: "No Will VS With Will VS With Family Trust",
        table: [
          {
            left: "Description",
            center1: "No Will",
            center2: "With Will",
            right: "Family Trust",
          },
          {
            left: "Timing of wealth inheritance",
            center1: "Delayed",
            center2: "6 months – 2 years",
            right: "Effective immediately",
          },
          {
            left: "Probate process",
            center1: "Required, can be complicated & lengthy",
            center2: "Required, but shorter",
            right: "Not required",
          },
          {
            left: "Preserved from mismanagement<br/>of assets",
            center1: "No",
            center2: "No",
            right: "Yes",
          },
          {
            left: "Confidentiality of distribution",
            center1: "No confidentiality & privacy",
            center2: "No confidentiality & privacy",
            right: "Yes. Disclosed to individual beneficiary only",
          },
          {
            left: "Effectiveness of distribution",
            center1:
              "Distribution of assets can be time consuming and assets may be unclaimed",
            center2: "Wealth will be distributed once for all",
            right: "Wealth is distributed as per Trust deed (customized)",
          },
          {
            left: "Preserved from tax erosion",
            center1: "No",
            center2: "No",
            right: "Yes",
          },
          {
            left: "Protected from creditor claim<br/>during lifetime",
            center1: "No",
            center2: "No",
            right: "Yes",
          },
          {
            left: "Extent of wealth inheritance",
            center1: "Limited to next generation only",
            center2:
              "Need to set up testamentary trust to distribute wealth over generations",
            right: "Multi-generational family wealth transfer is possible",
          },
          {
            left: "Dispute over wealth inheritance",
            center1: "Cannot be challenged",
            center2: "Will can be challenged",
            right:
              "Confidentiality of distribution makes it difficult to challenge the arrangements",
          },
        ],
      },
      RealEstateTrust: {
        title: "REAL ESTATE TRUST",
        imgs: "https://www.hongkongtrust.com.hk/vue/img/REALESTATETRUST1.png",
        img: "https://www.hongkongtrust.com.hk/vue/img/REALESTATETRUST2.png",
        text: [
          "Real estate trust refers to a trust deed between the settlor and the trustee, and the transfer of the immovable property in its name to the trustee. The trustee manages or distributes its immovable property for a specific purpose or the benefit of the beneficiary.",
        ],
        mintitle: "THE FUNCTIONS AND ADVANTAGES OF REAL ESTATE TRUST",
        content: [
          {
            title: "◆	Managed according to client’s wishes after death",
            text: "Property investors have good reasons to invest in this asset class. In order to reap good returns, each property needs to be managed well to maintain a high tenancy rate and rental yield. However, upon the owner’s death, who is going to own and manage these properties? Is it possible for one person to own and manage the properties, and yet the benefit is shared among a few beneficiaries? With a real estate trust, all these are possible.",
          },
          {
            title: "◆ Due diligence on third party service providers",
            text: "In order to reap good returns from property investments, a property investor needs to engage a reputable, reliable and trusted professional to facilitate the sale, purchase, tenancy and valuation of the properties. Whether the property is located in Hong Kong or overseas, how does the investor ensure that he/she is dealing with such a professional? Trustee company assists the settlor to perform a proper due diligence on any such professional prior to engaging them.",
          },
          {
            title: "◆	Sourcing of property data analytics",
            text: "The property sector is a cyclical one, so it is important for the property investor to make the right decisions to purchase, sell and set the right rental rate, from time to time. Where would the investor obtain reliable data to make such decisions? Settlors who have set up their trust with their trustee can get access to the network of property investment managers upon request.",
          },
          {
            title: "◆ Ease of wealth management",
            text: "During lifetime, the property investor would have built a sizeable property portfolio over time. Certain property investors may have also purchased properties in the name of other individuals. However, upon the death of the property investors, how will these properties be dealt with? Is it possible to consolidate the entire property portfolio with one reliable custodian who will manage it in a proper manner? Settling your properties into a real estate trust during lifetime can answer this question.",
          },
          {
            title: "◆ Immediate protection",
            text: "In the event of business failure or during any economic challenges, it is possible for business or personal creditors to go after the personal assets of an individual. Assets settled into a Hong Kong trust will gain immediate protection from creditors because the legal ownership of these assets would have been transferred to the trustee. Also, it is possible that these assets be excluded from any divorce settlement even if they were acquired during matrimonial years.",
          },
          {
            title: "◆	Privacy and identity protection",
            text: "Trustees in Hong Kong are obligated by law to maintain the privacy and confidentiality of the settlor’s personal information. It requires a court order from the Hong Kong court to compel the trustee to reveal the settlor’s personal identity and information. Based on the secrecy laws in Hong Kong, unless it is related to money laundering and terrorism, the chances for this to happen are very remote.",
          },
        ],
        TableTitle: "Property Held by Individuals VS Property Held in Trust",
        table: [
          {
            left: "Description",
            center: "Property Held by Individuals",
            right: "Property Held by Trust",
          },
          {
            left: "Manage according to owner’s wishes after death",
            center: "Another individual owes obligation",
            right: "Trustee owes fiduciary responsibility",
          },
          {
            left: "Due diligence on professionals",
            center: "By owner",
            right: "By trustee",
          },
          {
            left: "Sourcing of property data analytics",
            center: "Owner’s own sources",
            right: "Trustee’s network",
          },
          {
            left: "Ease of wealth management after death",
            center: "No",
            right: "Yes",
          },
          {
            left: "Immediate protection",
            center: "No",
            right: "Yes",
          },
          {
            left: "Privacy / identity protection",
            center: "No",
            right: "Yes",
          },
        ],
      },
      EducationTrust: {
        title: "EDUCATION TRUST",
        imgs: "https://www.hongkongtrust.com.hk/vue/img/EDUCATIONTRUST1.png",
        img: "https://www.hongkongtrust.com.hk/vue/img/EDUCATIONTRUST2.png",
        text: [
          "Education trust is aimed at preserving the assets dedicated to funding the tertiary education of your children so that they can enjoy peace of mind to complete their studies uninterrupted.",
          "As a parent, an education trust ensures that your obligations toward your children are fulfilled as a demonstration of your love toward them and for generations to come.",
        ],
        mintitle: "THE FUNCTIONS AND ADVANTAGES OF EDUCATION TRUST",
        content: [
          {
            title: "◆ Assets designated are secured",
            text: "The two aspects of a successful education funding are setting aside designated assets and ensuring its security. There should be one education income portfolio for each child, and assets designated for each child need to be managed as a separate portfolio. Placing the education income portfolio into a trust ensures that the funds are secured and protected from any eventual creditor claims or bankruptcy seizure of the parent(s).",
          },
          {
            title: "◆	Certainty of wishes fulfilled",
            text: "For parents who have drawn up specific plans and aspiration for their children’s future, they will do their best to ensure that these would become reality. But in the event of the parent(s) premature death, who will shoulder the responsibility of fulfilling these plans and aspirations? When the parent settles the designated assets into the Education Trust, the trustee will take over the role of ensuring that these plans be documented in the Trust Deed and/or letter of wishes.",
          },
          {
            title: "◆	Certainty of availability of funding",
            text: "Planning to have adequate funding available for tertiary education can be challenging if the rate of return on investment vehicles cannot be determined with certainty.",
          },
          {
            title: "◆ Certainty of overcoming inflation",
            text: "The inflation rates for most South East Asia, Australasia and North Asia countries are less than 4% (source: IndexMundi, 1 Jan 2020). This trust assurance that the purchasing power of the settlor’s wealth is not only maintained, but even grows over time.",
          },
        ],
        TableTitle: "Non-Trust Education Plans VS Education Trust",
        table: [
          {
            left: "Description",
            center: "Non-Trust Education Plans",
            right: "Education Trust",
          },
          {
            left: "Assets designated are secured",
            center: "Uncertain",
            right: "Yes",
          },
          {
            left: "Certainty of wishes fulfilled",
            center: "Uncertain",
            right: "Yes",
          },
          {
            left: "Certainty of availability of funding",
            center: "Uncertain",
            right: "Yes",
          },
          {
            left: "Certainty of overcoming inflation",
            center: "Uncertain",
            right: "Yes",
          },
        ],
      },
      InsuranceSavingsTrust: {
        title: "INSURANCE TRUST",
        imgs: "https://www.hongkongtrust.com.hk/vue/img/INSURANCE+SAVINGSTRUST1.png",
        img: "https://www.hongkongtrust.com.hk/vue/img/INSURANCE+SAVINGSTRUST2.png",
        text: [
          "Insurance Trust is a trust whereby the trustee is appointed to manage the proceeds of an insurance policy upon the death of the settlor. The trustee becomes the owner of the insurance policy and the settlor remains the insured person under the policy. Upon its maturity or when the proceeds are payable due to death or permanent disability of the settlor, the trustee will collect the proceeds and administer it in accordance with the settlor’s wishes as stated in the trust deed for the benefit of the beneficiaries. The trustee can also be instructed to pay the regular premiums of the policy on behalf of the beneficiaries.",
          "Please consult your Trust / Insurance Advisor to assist you in designing a portfolio to meet your unique needs.",
        ],
        mintitle: "THE FUNCTIONS AND ADVANTAGES OF INSURANCE TRUST",
        content: [
          {
            title: "◆ Certainty of non-lapsation of policy",
            text: "Payment for insurance premiums could be a lifetime commitment. One needs to be disciplined to ensure that the premiums are paid before they fall due. Policies do sometimes lapse due to oversight. The trustee can be instructed to make the regular premium payment.",
          },
          {
            title: "◆ Protected from creditor claims / bankruptcy seizure",
            text: "Settlors of a Hong Kong trust enjoy immediate protection from any creditor claims on their personal assets. This is because when the trust is set up, the settlor would have transferred the ownership of the selected assets to an offshore trustee.",
          },
          {
            title: "◆ Preservation of Insurance proceeds",
            text: "High-net-worth individuals will typically purchase life insurance to multiply their wealth in the event of death. Therefore, a substantial sum will be inherited by the beneficiaries out of the insurance proceeds upon death of that individual. Where the proceeds are settled into a trust, the settlor can choose to distribute smaller portions of their wealth over time to beneficiaries who are still young in age, or who lack the proper financial skills to manage substantial wealth, or who have certain negative habits like gambling, spendthrift, etc.",
          },
          {
            title: "◆ Single structure, double benefits",
            text: "With a single structure, the settlor of an inheritance savings trust can reap the benefit of one’s wealth being doubly protected by the inheritance savings trust as well as by the insurance policy.",
          },
          /* {
            title: "◆ Preservation of principal amount",
            text: "The ASR of the Savings Trust which provides the certainty of positive annual returns will ensure that the principal amount is preserved at maturity.",
          },
          {
            title: "◆ Single investment, double benefits",
            text: "Payment for life insurance premiums usually comes out of active income of a policyowner. When a Savings Trust is set up for the purpose of funding premiums for a life insurance policy, the premiums are paid out of a passive income source. Therefore, with a single investment, the settlor of a Savings Trust can reap the benefit of his/her wealth being doubly protected by the Savings Trust as well as by the insurance policy.",
          }, */
        ],
        TableTitle: "Non-Insurance Trust Plans VS Insurance Trust",
        table: [
          {
            left: "Description",
            center: "Insurance Only",
            right: "Insurance Trust",
          },
          {
            left: "Certainty of non-lapsation of policy",
            center: "No",
            right: "Yes",
          },
          {
            left: "Certainty of premium payments",
            center: "No",
            right: "Yes",
          },
          {
            left: "Protected from creditor claims<br/>/ bankruptcy seizures",
            center: "No",
            right: "Yes",
          },
          {
            left: "Preservation of insurance proceeds",
            center: "Uncertain",
            right: "Yes",
          },
          {
            left: "Single structure, double benefits",
            center: "No",
            right: "Yes",
          },
          /* {
            left: "Single investment, double benefits",
            center: "No",
            right: "Yes",
          }, */
        ],
      },
      CharityTrust: {
        title: "CHARITY TRUST",
        imgs: "https://www.hongkongtrust.com.hk/vue/img/CHARITYTRUST1.png",
        img: "https://www.hongkongtrust.com.hk/vue/img/CHARITYTRUST2.png",
        text: [
          "Charity trust means that the settlor entrusts its wealth to the trustee for the charitable purpose, and the trustee manages and distributes it in the name of the trustee according to the settlor's wishes to conducts charitable activities.",
        ],
        mintitle: "THE FUNCTIONS AND ADVANTAGES OF CHARITY TRUST",
        content: [
          {
            title: "◆ Perpetuity of charitable giving",
            text: "Those with a charitable attitude will usually wish that they can continue their charitable giving even beyond their lifetime.",
          },
          {
            title: "◆ Enhancement of family’s influence",
            text: "Where the charitable giving is done out of personal funds, it is usually done privately. However, if the charitable funds are channeled into a trust, the charitable giving can be done out of the name of the fund, for example, “Lim Family Charity Trust”. Using a trust for charitable donations could bring an enhancement to the family’s influence in the business community.",
          },
          {
            title:
              "◆ Cross-generation inheritance of family tradition and values",
            text: "Charitable donations are a way for families in business to give back to the community. Family patriarchs who started or continued the family business would wish to continue their charitable giving perpetually as it  is also a way of educating and passing down the legacy of good family values and tradition in charitable giving to the successive generations.",
          },
          {
            title: "◆ Certainty of continuation of charitable giving",
            text: "Upon the passing of the family patriarch, another member of the family will continue to make the charitable donations. But which member of the family can be trusted to continue this legacy across generations? Appointing a trustee will answer this question, as the family patriarch can document his wishes in the Trust Deed and/or letter of wishes, and the trustee will carry out their duties accordingly to fulfill those wishes, even across generations.",
          },
        ],
        TableTitle: "Non-Trust Charity Plans VS Charity Trust",
        table: [
          {
            left: "Description",
            center: "Non-Trust Charity Plans",
            right: "Charity Trust",
          },
          {
            left: "Perpetuity of charitable giving",
            center: "No",
            right: "Yes",
          },
          {
            left: "Enhancement of family’s influence",
            center: "No",
            right: "Yes",
          },
          {
            left: "Cross-generation inheritance of<br/>family tradition & values",
            center: "No",
            right: "Yes",
          },
          {
            left: "Certainty of continuation of<br/>charitable giving",
            center: "No",
            right: "Yes",
          },
        ],
      },
      MigrationTrust: {
        title: "MIGRATION TRUST",
        imgs: "https://www.hongkongtrust.com.hk/vue/img/MIGRATIONTRUST1.png",
        img: "https://www.hongkongtrust.com.hk/vue/img/MIGRATIONTRUST2.png",
        text: [
          "Migration trust is dedicated to wealth management for migration purposes and is inclusive of migration planning, wealth management and planning in order to facilitate the application procedure, shortening processing time and maximizing the chances of success.",
        ],
        mintitle: "THE FUNCTIONS AND ADVANTAGES OF MIGRATION TRUST",
        content: [
          {
            title: "◆	Proof of entitlement to assets as trust beneficiary",
            text: "As part of the migration application, the applicant will be requested to furnish proof of the legitimacy and existence of their assets and income sources. As a settlor who can also name yourself as a beneficiary, you may instruct your trustee to furnish proof of your entitlement to assets as a trust beneficiary. This may also help to elevate your profile in the eyes of the immigration authorities. Parents of children who are planning to migrate to another country can also set up a migration trust and naming their children as beneficiaries.",
          },
          {
            title: "◆	Certainty of availability of funding",
            text: "The application process for a migration may take years to be approved. You are likely to have set a target income to be achieved to bring to your new home country. Since time is on your side, you can plan to set aside a sum to grow towards that target amount.",
          },
          {
            title: "◆	Certainty of passive income to sustain daily living",
            text: "When you and your family settle down in your new country, there may be a time period before you start earning an income, possibly because you just started a business or you need to look for a job.",
          },
          {
            title: "◆	Potential avoidance of possible inheritance tax living",
            text: "The tax regime in your new home country may impose inheritance tax, which may be chargeable on your total assets upon death. However, if your assets are placed in a trust, this can help you to potentially avoid inheritance tax upon your passing.",
          },
          /* {
            title: "◆	Potential avoidance of possible inheritance tax living",
            text: "The tax regime in your new home country may impose inheritance tax, which may be chargeable on your total assets upon death. However, if your assets are placed in a trust, this can help you to potentially avoid inheritance tax upon your passing. When you have set up a trust account with us, you may choose to either purchase the capital assets and settle it into the trust, or to settle cash into the trust, and have the trust to purchase the said assets.",
          }, */
        ],
        TableTitle: "Non-Trust Plan For Migration VS Migration Trust",
        table: [
          {
            left: "Description",
            center: "Non-Trust Plan For Migration",
            right: "Migration Trust",
          },
          {
            left: "Proof of legitimacy & existence of asset",
            center: "Self-declare",
            right: "Independently verified<br/>by trustee",
          },
          {
            left: "Possible avoidance of income tax",
            center: "No",
            right: "Yes",
          },
          {
            left: "Certainty of availability of funding",
            center: "No",
            right: "Yes",
          },
          {
            left: "Certainty of passive income to sustain daily living post-migration",
            center: "No",
            right: "Yes",
          },
          {
            left: "Potential avoidance of possible inheritance tax",
            center: "No",
            right: "Yes",
          },
        ],
      },
      SpecialKidsTrust: {
        title: "SPECIAL KIDS’ TRUST",
        imgs: "https://www.hongkongtrust.com.hk/vue/img/SPECIALKIDSTRUST1.png",
        img: "https://www.hongkongtrust.com.hk/vue/img/SPECIALKIDSTRUST2.png",
        text: [
          "Parents of special children who are not likely to be able to live independently can set up this trust, which will ensure both the physical and financial well-being of that child for life. Such a trust can see to the fulfillment of your aspirations as a loving parent after your lifetime.",
        ],
        mintitle: "THE FUNCTIONS AND ADVANTAGES OF SPECIAL KIDS' TRUST",
        content: [
          {
            title: "◆	Planning and preserving specific financial assets",
            text: "An important aspect of building a income for the special child’s future is the setting aside of designated assets and ensuring its security. Setting aside the designated assets enables it to be managed as a separate portfolio. Placing the special child’s income portfolio into a trust ensures that the funds are secured and protected from any eventual creditor claims or bankruptcy seizure of the parent(s). Assets in a trust will also bypass the probate / administration process upon the death of the parent.",
          },
          {
            title: "◆	Specific caregiver appointed",
            text: "The best people who will be able to determine the best caregivers are the parents themselves. Whether it is a special home for the disabled, or with a relative / friend, the parents can make this decision in their lifetime just in case both parent(s) are no longer around. Specifying the most suitable caregiver(s) is an important aspect of the Special Kids’ Trust, as this will help to determine the financial resources needed for the child’s maintenance and well-being. Your advisor can then assist to determine an adequate amount to be settled into the said trust to meet these financial obligations.",
          },
          {
            title: "◆	Supervision of caregiver and child well-being",
            text: "As time goes by, the parent will wish for an independent person to ensure that the caregiver continuously maintains their standards of service for the special child. When such assets are settled into a trust, the trustee will play this role as there will be constant communication with the caregiver. The parent can also appoint a Trust Protector (a close friend or relative) to liaise between the trustee and the caregiver.",
          },
          {
            title: "◆	Immediate liquidity",
            text: "As the timing of death is uncertain, it is best that the parent of a special child set up an emergency financial resource which will take over the parents’ role of providing for the special child. But who will be a suitable party to hold the emergency income? Using a trust, the parent can set aside certain income resources in the trust for emergency purposes, such that when the trustee is notified of the parent’s death, the trustee will channel the funds accordingly.",
          },
          {
            title: "◆	Peace of mind",
            text: "When the parent sets up the trust during lifetime, the parent can get to experience the reliability of the services of the trustee, thereby giving him/her a peace of mind that the trustee is able to carry out their wishes for their special child in the event of their absence. ",
          },
          /* {
            title: "◆	Peace of mind",
            text: "When the parent sets up the trust during lifetime, the parent can get to experience the reliability of the services of the trustee, thereby giving him/her a peace of mind that the trustee is able to carry out their wishes for their special child in the event of their absence.",
          }, */
        ],
        TableTitle: "Special Kids Income (Non-Trust) VS Special Kids’ Trust",
        table: [
          {
            left: "Description",
            center: "Special Kids Income<br>(Non-Trust)",
            right: "Special Kids’ Trust",
          },
          {
            left: "Setting aside specific financial assets",
            center: "No",
            right: "Yes",
          },
          {
            left: "Specific caregiver appointed",
            center: "No",
            right: "Yes",
          },
          {
            left: "Supervision of caregiver<br/>& child well-being",
            center: "No",
            right: "Yes",
          },
          {
            left: "Immediate liquidity",
            center: "No",
            right: "Yes",
          },
          {
            left: "Peace of mind",
            center: "No",
            right: "Yes",
          },
          /*  {
            left: "Peace of mind",
            center: "No",
            right: "Yes",
          }, */
        ],
      },
    };
  },
  mounted() {
    this.router = this.$route.query.id;
    /* console.log(this.data); */
    switch (this.router) {
      case "SavingsTrust":
        this.data = Object.assign({}, this.SavingsTrust);
        document.getElementById("img").style.marginTop = 0;
        break;
      case "FamilyTrust":
        this.data = Object.assign({}, this.FamilyTrust);
        document.getElementById("img").style.marginTop = 0;
        this.isshow = !this.isshow;
        break;
      case "RealEstateTrust":
        this.data = Object.assign({}, this.RealEstateTrust);
        break;
      case "Insurance SavingsTrust":
        this.data = Object.assign({}, this.InsuranceSavingsTrust);
        break;
      case "CharityTrust":
        this.data = Object.assign({}, this.CharityTrust);
        break;
      case "MigrationTrust":
        this.data = Object.assign({}, this.MigrationTrust);
        break;
      case "EducationTrust":
        this.data = Object.assign({}, this.EducationTrust);
        break;
      case "SpecialKidsTrust":
        this.data = Object.assign({}, this.SpecialKidsTrust);
        break;
      case "Others":
        this.Other = false;
        break;
    }
    /* console.log(this.index); */
  },
};
</script>
<style lang="scss" scope>
.Categor {
  .nav .text div:nth-child(2) {
    background: #d2d2d2;
  }
  .nav .text div:nth-child(5) {
    p {
      margin-bottom: 10px;
    }
  }
  .active {
    padding-top: 20px;
    background-color: #fff;
  }
  .nav .text div:nth-child(4) {
    background: #d2d2d2;
  }
  .nav .text div:nth-child(6) {
    background: #d2d2d2;
  }
  .nav .text div:nth-child(8) {
    background: #d2d2d2;
  }
  .nav .text div:nth-child(10) {
    background: #d2d2d2;
  }
  .tds .tdss:nth-child(1) {
    font-weight: 500;
  }
  .nav .table .td .tds .tdss .center {
    white-space: pre-wrap;
  }
  .tds .tdss .left {
    font-weight: 500;
  }
  .tdS .tdSs:nth-child(1) {
    font-weight: 500;
  }
  .nav .table .td .tdS .tdSs .center {
    white-space: pre-wrap;
  }
  .tdS .tdSs .left {
    font-weight: 500;
  }
  .min {
    img {
      margin-top: 0px;
    }
  }
  img {
    margin-top: 20px;
  }
  #img {
    margin-top: 1px;
  }
  .nav {
    width: 60vw;
    text-align: left;
    margin: 100px auto 100px;
    div {
      line-height: 1;
      img {
        margin-top: 0px;
      }
    }
    h2 {
      font-size: 44px;
      margin: 0px;
      padding-left: 1%;
    }
    h4 {
      margin-top: 10px;
      font-size: 20px;
      margin-bottom: 0px;
      padding: 1%;
      padding-bottom: 0px;
    }
    p {
      margin: 0;
      text-align: justify;
      /* margin-top: 10px; */
      line-height: 1.5;
      padding: 1%;
      font-family: "contert";
    }

    .table {
      margin-top: 50px;
      h2 {
        font-size: 26px;
      }
      .td {
        margin-top: 30px;
        .tds {
          background: #d2d2d2;

          /* padding-bottom: 30px; */
          .tdss {
            width: 100%;
            border-bottom: 1px solid #fff;
            font-size: 20px;
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            justify-content: center;
            align-items: stretch;
            div {
              padding: 10px 0;
              line-height: 1.5;
            }
          }
          .left {
            width: 44%;
            display: flex;
            align-items: center;
            justify-content: center;
            div {
              border-bottom: none;
            }
          }
          .center {
            width: 28%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;
            div {
              border-bottom: none;
            }
          }
          .right {
            width: 27.7%;
            display: flex;
            align-items: center;
            justify-content: center;
            div {
              border-bottom: none;
            }
          }
        }
        .tdS {
          background: #d2d2d2;
          /* padding-bottom: 30px; */
          .tdSs {
            width: 100%;
            border-bottom: 1px solid #fff;
            font-size: 20px;
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            justify-content: center;
            align-items: stretch;
            div {
              padding: 10px 0;
              line-height: 1.5;
            }
          }
          .left {
            width: 41%;
            display: flex;
            align-items: center;
            justify-content: center;
            div {
              border-bottom: none;
            }
          }
          .center1 {
            width: 19.2%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #fff;
            div {
              border-bottom: none;
            }
          }
          .center2 {
            width: 19.2%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;
            div {
              border-bottom: none;
            }
          }
          .right {
            width: 19.2%;
            display: flex;
            align-items: center;
            justify-content: center;
            div {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
  .Other {
    .banner {
      width: 100vw;
      /*  height: 994px;
  background: url("../assets/banner8.png") no-repeat;
  background-size: cover; */
      position: relative;
    }
    .title {
      color: #fff;
      display: flex;
      width: 100vw;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* z-index: 100; */
      position: absolute;
      top: 45%;
      font-size: 55px;
    }
    .nav {
      width: 60vw;
      margin: 130px auto 130px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      img {
        width: 100%;
      }
      .content {
        width: 31%;
        margin-bottom: 30px;
        div {
          text-align: center;
          font-size: 20px;
          margin-top: 10px;
        }
        .OTHERS {
          display: flex;
          border: 1px solid;
          /* height: 61%; */
          justify-content: center;
          align-items: center;
          p {
            margin: 21.5% 0px;
          }
        }
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1500px) {
  .Categor {
    .nav {
      width: 80vw;
    }
    .Other {
      .nav {
        width: 80vw;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .Categor {
    .nav {
      width: 80vw;
      margin-top: 75px;
      margin-bottom: 100px;
      h2 {
        font-size: 30px;
      }
      h4 {
        margin-top: 20px;
      }
      .table {
        margin-top: 40px;
        h2 {
          font-size: 24px;
        }
        .td {
          .tdS {
            .tdSs {
              font-size: 16px;
            }
          }
          .tds {
            .center {
              width: 27.5%;
            }
            .tdss {
              font-size: 16px;
            }
          }
        }
      }
    }
    .Other {
      .title {
        font-size: 36px;
      }
      .nav {
        width: 80vw;
        margin: 65px auto 100px;
        .content {
          .OTHERS {
            p {
              margin: 20% 0px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  // // 手机背景图
  // .banner img {
  //   height: 520px;
  //   object-fit: cover;
  // }
  .Categor {
    .nav {
      width: 94vw;
      margin: 70px auto 20px;
      p {
        font-size: 12px;
      }
      h4 {
        font-size: 16px;
        margin-top: 20px;
        line-height: 1.5;
      }
      h2 {
        font-size: 20px;
      }
      img {
        margin-top: 10px;
      }
      .table {
        margin-top: 30px;
        h2 {
          font-size: 16px;
          line-height: 1.5;
        }
        .td {
          /* width: 95vw; */
          /* position: absolute;
        left: 2.5vw; */
          .tdS {
            .tdSs {
              font-size: 10px;
              .left {
                width: 29%;
              }
              .right {
                width: 28.1%;
              }
              .center1 {
                width: 21%;
              }
              .center2 {
                width: 21%;
              }
            }
          }
          .tds {
            .tdss {
              font-size: 10px;
              .left {
                width: 43%;
              }
            }
          }
        }
      }
    }
    .Other {
      .title {
        font-size: 20px;
        top: 60%;
      }
      .nav {
        width: 90vw;
        margin: 30px auto 20px;
        /* .content:nth-child(9) {
      display: none;
    } */
        .content {
          width: 48%;
          div {
            font-size: 14px;
            font-weight: 500;
          }
          .text {
            font-size: 12px;
            margin-bottom: 5px;
            font-weight: 300;
            /* display: none; */
          }
        }
      }
    }
  }
}
</style>
